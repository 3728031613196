import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../utils/status";
import {
  getGamaMobilityBlogContent,
  getGamaMobilityContact,
  getGamaMobilityHomeContent,
  getGamaMobilityUSContent,
} from "./thunk";

const initialState = {
  common: {
    navbar: null,
    footer: null,
  },
  home: {
    header: null,
    sections: [],
  },

  contact: {
    header: null,
    sections: [],
  },

  us: {
    header: null,
    sections: [],
  },

  blog: {
    header: null,
    sections: [],
  },

  status: STATUS.IDLE,
  error: null,
};

export const gamaMobilitySlice = createSlice({
  name: "gamamobility",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGamaMobilityHomeContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaMobilityHomeContent.fulfilled, (state, action) => {
        state.common.navbar = action.payload.navbar;
        state.home.header = action.payload.header;
        state.home.sections = action.payload.sections;
        state.common.footer = action.payload.footer;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaMobilityHomeContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaMobilityBlogContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaMobilityBlogContent.fulfilled, (state, action) => {
        state.blog.header = action.payload.header;
        state.blog.sections = action.payload.sections[0];
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaMobilityBlogContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaMobilityContact.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaMobilityContact.fulfilled, (state, action) => {
        state.contact.header = action.payload.header;
        state.contact.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaMobilityContact.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaMobilityUSContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaMobilityUSContent.fulfilled, (state, action) => {
        state.us.header = action.payload.header;
        state.us.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaMobilityUSContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      });
  },
});
//Commons
export const getNavbarContent = (state) => state.gamamobility.common.navbar;
export const getFooterContent = (state) => state.gamamobility.common.footer;
//Home
export const getHomeHeaderContent = (state) => state.gamamobility.home.header;
export const getHomeSectionsContent = (state) =>
  state.gamamobility.home.sections;

export const getGamaMobilityContactSections = (state) =>
  state.gamamobility.contact.sections;
export const getGamaMobilityContactHeader = (state) =>
  state.gamamobility.contact.header;

export const getGamaMobilityUsHeader = (state) => state.gamamobility.us.header;
export const getGamaMobilityUsSections = (state) =>
  state.gamamobility.us.sections;

export const getGamaMobilityBlogHeader = (state) =>
  state.gamamobility.blog?.header;
export const getGamaMobilityBlogSections = (state) =>
  state.gamamobility.blog?.sections;

export const getStatus = (state) => state.gamamobility.status;
//Fleet

export default gamaMobilitySlice.reducer;
