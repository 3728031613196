import RoutesWrapper from "../routes/index";

function App() {
  return (
    <div>
      <RoutesWrapper />
    </div>
  );
}

export default App;
