import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import { Col, Form, Image, Input, Layout, Row, Select, Typography } from "antd";

import React, { useEffect, useState } from "react";
import Button from "../../components/Button/index";
import Footer from "../../components/layouts/HomeLayout/Footer";
import ContactBanner from "../../assets/img/contact-banner.png";
import { useLocation } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-mobility.png";
import { useDispatch, useSelector } from "react-redux";
import { getGamaMobilityContact } from "../../store/strapi/thunk";
import {
  getGamaMobilityContactHeader,
  getGamaMobilityContactSections,
} from "../../store/strapi";

function ContactMobility() {
  const [form] = Form.useForm();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamaMobilityContact());
  }, []);

  const contactSections = useSelector(getGamaMobilityContactSections);
  const contactHeader = useSelector(getGamaMobilityContactHeader);

  console.log(contactHeader, " CONTACTTT");

  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();

  useEffect(() => {
    form.setFieldsValue({
      "vehicle-number": "1",
      phonePrefix: "56",
    });
  }, []);

  const onFinish = async (values) => {
    // try {
    //   values.phoneNumber = `+${values.phonePrefix}${values.phoneNumber}`;
    //   delete values.phonePrefix;
    //   const payload = { ...values };
    //   // const { data } = await sendUsedPayload(payload);
    //   if (data.success)
    //     return Modal.success({
    //       content: "Datos enviados con éxito",
    //       onOk() {
    //         window.location.replace("/");
    //       },
    //     });
    // } catch (error) {
    //   if (error)
    //     return Modal.error({
    //       content: "Ha ocurrido un error al enviar los datos",
    //     });
    // }
  };

  useEffect(() => {
    form.setFieldsValue({
      phonePrefix: "56",
    });
  }, []);

  const { scrollDirection } = useScroll();

  useEffect(() => {
    if (window.location.pathname === "/contactMobility") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const styles = {
    active: {
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const { Text } = Typography;
  return (
    <div>
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `60vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                contactHeader?.length === 0 || contactHeader === null
                  ? ContactBanner
                  : contactHeader?.bannerImage?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <Link to={"/"}>GAMA MOBILITY</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "/"
                            ? "#FF3200"
                            : "white",
                      }}
                      href={"https://gamaleasing.cl/"}
                      rel="noreferrer"
                    >
                      GAMA LEASING
                    </a>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamaleasing.cl/usados"}
                      rel="noreferrer"
                    >
                      GAMA USADOS
                    </a>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image preview={false} src={Logo} width={170} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contactMobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a
                                target="_blank"
                                href="https://gamaleasing.cl/branches"
                                rel="noreferrer"
                              >
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                target="_blank"
                                href="https://gamaleasing.cl/branches"
                                rel="noreferrer"
                              >
                                Sucursales
                              </a>
                              <Link to="/mobility">Nuestros Servicios</Link>
                              <Link to="/mobility">Quienes Somos</Link>
                              <Link to="/mobility">Beneficios</Link>
                              <Link to="/mobility">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : // <Burger
                      //   data={burguerData}
                      //   modal={visible}
                      //   onClose={() => {
                      //     setVisible(!visible);
                      //   }}
                      // />
                      null}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "left",
                paddingLeft: biggerThan900 ? "8.125rem" : "20px",
              }}
            >
              {contactHeader?.length === 0 || contactHeader === null
                ? "Conversemos"
                : contactHeader?.title}
            </h1>
            <h3
              style={{
                textAlign: "left",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",
                maxWidth: biggerThan900 ? "" : "300px",
                // marginRight: biggerThan900 ? "1120px" : "",
                paddingLeft: biggerThan900 ? "8.125rem" : "0",
              }}
            >
              {contactHeader?.length === 0 || contactHeader === null
                ? "Programa una consulta gratuita con nuestro equipo."
                : contactHeader?.detail}
            </h3>
          </div>
        </section>
      </Layout>
      <Row>
        <Col span={7}>
          {biggerThan900 ? (
            <Row
              style={{
                backgroundColor: "#2D2D2D",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    marginBottom: "10px",
                    marginTop: " 40px",
                  }}
                >
                  {contactSections?.length === 0 || contactSections === null
                    ? "Contacto"
                    : contactSections && contactSections[0]?.Title}
                </Text>
                <Row
                  justify="start"
                  style={{ color: "white", marginBottom: "5px" }}
                >
                  <Col>
                    <PhoneOutlined
                      style={{ color: "#FF3200", marginRight: "15px" }}
                    />
                  </Col>
                  <Col style={{ color: "white" }}>+56 44 222 9975</Col>
                </Row>
                <Row>
                  <Col>
                    <MailOutlined
                      style={{ color: "#FF3200", marginRight: "15px" }}
                    />
                  </Col>
                  <Col style={{ color: "white" }}>
                    {contactSections?.length === 0 || contactSections === null
                      ? "contacto@gamaleasing.cl"
                      : contactSections && contactSections[0]?.Card[1]?.Title}
                  </Col>
                </Row>
                <Text
                  style={{
                    color: "white",
                    marginTop: "30px",
                    marginBottom: "15px",
                  }}
                >
                  {contactSections?.length === 0 || contactSections === null
                    ? "Siguenos"
                    : contactSections && contactSections[1]?.Title}
                </Text>
                <Row justify="space-evenly" style={{ width: "200px" }}>
                  <Col>
                    <a href="https://www.facebook.com/gamaleasing2013">
                      <FacebookFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href="https://www.instagram.com/gamaleasing/">
                      <InstagramFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href="https://www.linkedin.com/company/gamaleasing/mycompany/">
                      <LinkedinFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href="https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q">
                      <YoutubeFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                </Row>
              </Row>
            </Row>
          ) : null}
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Row
            style={{
              flexDirection: "column",
              border: "1px solid rgba(255, 50, 0, 0.1)",
              margin: "50px 30px 50px 60px",
              padding: "50px 50px 0px 50px",
              borderRadius: "17px",
            }}
          >
            <Col>
              <Text strong style={{ fontSize: "20px" }}>
                {contactSections?.length === 0 || contactSections === null
                  ? "¿Que necesitas?"
                  : contactSections && contactSections[2]?.Title}
              </Text>
            </Col>
            <Col style={{ paddingBottom: "30px" }}>
              <Text>
                {contactSections?.length === 0 || contactSections === null
                  ? "Llena el siguiente formulario y te responderemos a la brevedad."
                  : contactSections && contactSections[2]?.Paragraph}
              </Text>
            </Col>
            <Col>
              <Row style={{ marginBottom: "25px" }}>
                <Button
                  style={{
                    marginBottom: biggerThan900 ? "" : "15px",
                    backgroundColor: "white",
                    color: "black",
                    marginRight: "15px",
                  }}
                >
                  <a
                    href={
                      contactSections?.length === 0 || contactSections === null
                        ? "https://www.gamarent.cl/contacto"
                        : contactSections &&
                          contactSections[2]?.Button[0]?.Hreference
                    }
                  >
                    {contactSections?.length === 0 || contactSections === null
                      ? "Rent a Car"
                      : contactSections && contactSections[2]?.Button[0]?.Label}
                  </a>
                </Button>
                <Button
                  style={{
                    marginBottom: biggerThan900 ? "" : "15px",
                    backgroundColor: "white",
                    color: "black",
                    marginRight: "15px",
                  }}
                >
                  <a
                    href={
                      contactSections?.length === 0 || contactSections === null
                        ? "https://www.tripp.cl/contactenos"
                        : contactSections &&
                          contactSections[2]?.Button[1]?.Hreference
                    }
                  >
                    {contactSections?.length === 0 || contactSections === null
                      ? "Tripp"
                      : contactSections && contactSections[2]?.Button[1]?.Label}
                  </a>
                </Button>
                <Button
                  style={{
                    marginBottom: biggerThan900 ? "" : "15px",
                    backgroundColor: "white",
                    color: "black",
                    marginRight: "15px",
                  }}
                >
                  <a
                    href={
                      contactSections?.length === 0 || contactSections === null
                        ? "https://gamaleasing.cl/contactUsed"
                        : contactSections &&
                          contactSections[2]?.Button[2]?.Hreference
                    }
                  >
                    {contactSections?.length === 0 || contactSections === null
                      ? "Gama Usados"
                      : contactSections && contactSections[2]?.Button[2]?.Label}
                  </a>
                </Button>
                <Button
                  style={{
                    marginBottom: biggerThan900 ? "" : "15px",
                    backgroundColor: "white",
                    color: "black",
                    marginRight: "15px",
                  }}
                >
                  <a
                    href={
                      contactSections?.length === 0 || contactSections === null
                        ? "https://gamaleasing.cl/contact/"
                        : contactSections &&
                          contactSections[2]?.Button[3]?.Hreference
                    }
                  >
                    {contactSections?.length === 0 || contactSections === null
                      ? "Gama Leasing"
                      : contactSections && contactSections[2]?.Button[3]?.Label}
                  </a>
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer logo={Logo} />
    </div>
  );
}

export default ContactMobility;
