import { Col, Image, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/image 71.webp";
import logo2 from "../../assets/img/image 72.webp";
import logo3 from "../../assets/img/12.webp";
import MobilityBanner from "../../assets/img/mobilitybanner.png";
import { useScroll } from "../../utils/useScroll";
import { useMediaPredicate } from "react-media-hook";
import Logo from "../../assets/img/logo-mobility.png";
import Button from "../../components/Button";
import Logo1 from "../../assets/img/image 73.png";
import Logo2 from "../../assets/img/image 74.png";
import Logo3 from "../../assets/img/image 75.png";
import Camioneta from "../../assets/img/kisspng-2018.png.png";
import Compromise1 from "../../assets/img/001-about-noun_fast_359305-1-1.png.png";
import Compromise2 from "../../assets/img/002-about-noun_success_1195075-1-1.png.png";
import Compromise3 from "../../assets/img/003-about-noun_efficiency_3356873-1-1.png.png";
import Compromise4 from "../../assets/img/004-about-noun_Idea_2053564-1-1.png.png";
import Compromise5 from "../../assets/img/005-about-noun_employees_2072725-1-1.png.png";
import Compromise6 from "../../assets/img/006-about-noun_Hire-Car_121692-1-1.png.png";
import Mobility from "../../assets/img/about-002.jpg.png";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Footer from "../../components/layouts/HomeLayout/Footer";
import GoToTop from "../../components/GoToTop";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import MobiliySvg1 from "../../assets/img/rent a car.svg";
import MobiliySvg2 from "../../assets/img/leasing.svg";
import MobiliySvg3 from "../../assets/img/renting.svg";
import MobiliySvg4 from "../../assets/img/usados.svg";
import { useDispatch, useSelector } from "react-redux";
import { getGamaMobilityUSContent } from "../../store/strapi/thunk";
import {
  getGamaMobilityUsHeader,
  getGamaMobilityUsSections,
} from "../../store/strapi";
function MobilityUS() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const biggerThan1300 = useMediaPredicate("(min-width: 1420px)");
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const [isHome, setIsHome] = useState();

  useEffect(() => {
    dispatch(getGamaMobilityUSContent());
  }, []);

  const usHeader = useSelector(getGamaMobilityUsHeader);
  const usSections = useSelector(getGamaMobilityUsSections);
  console.log(usSections, " US HEADER");

  useEffect(() => {
    if (window.location.pathname === "/mobilityUS") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);
  const options = {
    rewind: true,
    type: "loop",
    // autoplay: true,
    perPage: 1,
    perMove: 1,
  };
  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const compromises = [
    {
      title: "Rapidez y calidad de servicio",
      description:
        "Entregamos un servicio de calidad, enfocado en proporcionar soluciones rápidas a las necesidades de Movilidad de nuestros clientes.",
      icon: Compromise1,
    },
    {
      title: "Satisfacción de clientes y proveedores",
      description:
        "Aseguramos la satisfacción de nuestros clientes, tanto durante la entrega de nuestros servicios como durante el proceso de post venta.",
      icon: Compromise2,
    },
    {
      title: "eficiencia y continuidad operacional",
      description:
        "Aseguramos la continuidad operacional de tu flota y brindamos un servicio eficaz, 100% Movilidad a todos nuestros clientes, para que tú o tu empresa siempre estén en movimiento.",
      icon: Compromise3,
    },
    {
      title: "Soluciones de movilidad flexibles y personalizadas",
      description:
        "Contamos con soluciones hechas a medida para satisfacer cada uno de los requerimientos y necesidades de nuestros clientes, ya sea en arriendo de flotas para empresas o de autos para particulares.",
      icon: Compromise4,
    },
    {
      title: "Experiencia en el rubro",
      description:
        "Nuestro equipo especializado y amplia experiencia en el rubro nos avalan como uno de los mejores operadores en Movilidad de Chile.",
      icon: Compromise5,
    },
    {
      title: "Innovación",
      description:
        "Contamos con opciones de Leasing Operativo, Renting, Rent a Car y venta de Autos Usados y Seminuevos, entregando una variada gama de soluciones de Movilidad.",
      icon: Compromise6,
    },
  ];

  const onClose = () => {
    setVisible(!visible);
  };
  const cleanModal = () => {
    document.getElementById("checkbox4").click();
    onClose();
  };

  const { scrollDirection } = useScroll();
  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `80vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                usHeader?.length === 0 || usHeader === null
                  ? MobilityBanner
                  : usHeader?.bannerImage?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <Link to={"/mobility"}>GAMA MOBILITY</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      href={"https://gamaleasing.cl/"}
                      rel="noreferrer"
                    >
                      GAMA LEASING
                    </a>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamaleasing.cl/usados"}
                      rel="noreferrer"
                    >
                      GAMA USADOS
                    </a>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contactMobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a
                                target="_blank"
                                smooth
                                href="https://gamaleasing.cl/branches"
                                rel="noreferrer"
                              >
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                target="_blank"
                                href="https://gamaleasing.cl/branches"
                                rel="noreferrer"
                              >
                                Sucursales
                              </a>
                              <Link to="/mobility">Nuestros Servicios</Link>
                              <Link to="/mobility">Quienes Somos</Link>
                              <Link to="/mobility">Beneficios</Link>
                              <Link to="/mobility">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            id="checkbox4"
                            className="checkbox4 visuallyHidden"
                          ></input>
                          <label htmlFor="checkbox4" onClick={() => onClose()}>
                            <div className="hamburger hamburger4">
                              <span className="bar bar1"></span>
                              <span className="bar bar2"></span>
                              <span className="bar bar3"></span>
                              <span className="bar bar4"></span>
                              <span className="bar bar5"></span>
                            </div>
                          </label>

                          <div
                            className={
                              visible === true ? "modal modal-active" : "modal"
                            }
                          >
                            <div className="delimiter"></div>
                            <div className="container-modal">
                              <div
                                className="header-modal"
                                style={{ height: "55%" }}
                              >
                                <div className="nav-group">
                                  <a
                                    smooth
                                    href="https://gamaleasing.cl/branches"
                                    onClick={cleanModal}
                                  >
                                    Sucursales
                                  </a>
                                  <a
                                    smooth
                                    href="/mobility"
                                    onClick={cleanModal}
                                  >
                                    Nuestros servicios
                                  </a>
                                  <a
                                    smooth
                                    href="/mobility"
                                    onClick={cleanModal}
                                  >
                                    Quienes Somos
                                  </a>
                                  <a
                                    smooth
                                    href="/mobility"
                                    onClick={cleanModal}
                                  >
                                    Beneficios
                                  </a>
                                  <a
                                    smooth
                                    href="/mobility"
                                    onClick={cleanModal}
                                  >
                                    Publicaciones
                                  </a>
                                </div>

                                <div className="button-group">
                                  <a
                                    target="_blank"
                                    href="/contactMobility"
                                    rel="noreferrer"
                                  >
                                    <Button className="gama-outline talk-button">
                                      Conversemos
                                    </Button>
                                  </a>
                                </div>
                              </div>
                              <div
                                className="footer-modal"
                                style={{
                                  height: "45%",
                                  backgroundColor: "#2D2D2D",
                                }}
                              >
                                <ul style={{ paddingBottom: "15px" }}>
                                  <li>
                                    <Link
                                      style={{
                                        color:
                                          window.location.pathname === "/"
                                            ? "#FF3200"
                                            : "white",
                                      }}
                                      to={"/"}
                                    >
                                      GAMA MOBILITY
                                    </Link>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/"}
                                      rel="noreferrer"
                                    >
                                      GAMA LEASING
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.gamarent.cl/"}
                                      rel="noreferrer"
                                    >
                                      RENT A CAR
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.tripp.cl/"}
                                      rel="noreferrer"
                                    >
                                      TRIPP
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/usados"}
                                      rel="noreferrer"
                                    >
                                      GAMA USADOS
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container" style={{ marginTop: "25px" }}>
            <Row>
              <Row
                id="benefits"
                justify="center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: biggerThan900 ? "50px" : "0px",
                }}
              >
                <Row
                  justify="center"
                  align="start"
                  style={{
                    display: biggerThan900 ? "flex" : "",
                    alignItems: biggerThan900 ? "flex-start" : "",
                    flexDirection: biggerThan900 ? "column" : "",
                    justifyContent: biggerThan900 ? "center" : "",
                  }}
                >
                  <Col
                    style={{
                      fontSize: "40px",
                      fontWeight: "700",
                      marginBottom: "16px",
                      color: "white",
                    }}
                  >
                    {usHeader?.length === 0 || usHeader === null
                      ? "Quienes Somos"
                      : usHeader?.title}
                  </Col>
                  <Col
                    style={{
                      width: biggerThan900 ? "500px" : "80%",
                      lineHeight: "20px",
                      marginBottom: "26px",
                      color: "white",
                    }}
                  >
                    {usHeader?.length === 0 || usHeader === null
                      ? "Gama es una Sociedad compuesta por Empresas Penta, Grupo Indumotora y Eurofrance. Nace para satisfacer la demanda del mercado en el arrendamiento de flotas de vehículos para empresas o de autos para particulares en el país, a través de los servicios de Gama Leasing, Tripp, Gama Rent a Car y Gama Usados."
                      : usHeader?.detail}
                  </Col>
                </Row>

                <Row
                  align="middle"
                  style={{
                    display: biggerThan900 ? "flex" : "",
                    alignItems: biggerThan900 ? "flex-start" : "",
                    flexDirection: biggerThan900 ? "" : "column",
                    justifyContent: biggerThan900 ? "center" : "",
                  }}
                >
                  <img
                    style={{
                      width: "102px",
                      height: "50px",
                      marginRight: "15px",
                    }}
                    src={
                      usSections?.length === 0 || usSections === null
                        ? Logo2
                        : usSections &&
                          usSections[0]?.Card[0]?.Icon?.data?.attributes?.url
                    }
                  />
                  <img
                    style={{
                      width: "165px",
                      height: "18px",
                      marginRight: "15px",
                    }}
                    src={
                      usSections?.length === 0 || usSections === null
                        ? Logo1
                        : usSections &&
                          usSections[0]?.Card[1]?.Icon?.data?.attributes?.url
                    }
                  />
                  <img
                    style={{
                      width: "158px",
                      height: "43px",
                      marginRight: "15px",
                    }}
                    src={
                      usSections?.length === 0 || usSections === null
                        ? Logo3
                        : usSections &&
                          usSections[0]?.Card[2]?.Icon?.data?.attributes?.url
                    }
                  />
                </Row>
              </Row>
              <Row>
                {biggerThan900 ? (
                  <img
                    style={{
                      marginTop: "80px",
                      marginLeft: "26px",
                      width: biggerThan900 ? "auto" : "250px",
                      height: "auto",
                    }}
                    src={
                      usSections?.length === 0 || usSections === null
                        ? Camioneta
                        : usSections &&
                          usSections[0]?.Image?.data?.attributes?.url
                    }
                  />
                ) : null}
              </Row>
            </Row>
          </div>
        </section>
      </Layout>
      <Row justify="center">
        <Col
          style={{
            fontSize: biggerThan900 ? "50px" : "35px",
            fontWeight: "600",
            marginTop: "78px",
          }}
        >
         {usSections?.length === 0 || usSections === null
            ? "Nuestro Compromiso" : usSections && usSections[1]?.Title}
        </Col>
      </Row>
      {biggerThan900 ? (
        <Row
          justify="center"
          style={{ display: "flex", marginTop: "70px", marginLeft: "200px" }}
        >
          {usSections?.length === 0 || usSections === null
            ? compromises.map((compromise) => (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    width: biggerThan1300 ? "500px" : "400px",
                  }}
                >
                  <Col>
                    <img
                      style={{ marginBottom: "15px" }}
                      src={compromise.icon}
                    />
                  </Col>
                  <Col
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      width: "250px",
                    }}
                  >
                    {compromise.title}
                  </Col>
                  <Col
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      width: "280px",
                      marginBottom: "50px",
                    }}
                  >
                    {compromise.description}
                  </Col>
                </Row>
              ))
            : usSections &&
              usSections[1]?.Card?.map((compromise) => (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    width: biggerThan1300 ? "500px" : "400px",
                  }}
                >
                  <Col>
                    <img
                      style={{
                        marginBottom: "15px",
                      }}
                      src={compromise?.Icon?.data?.attributes?.url}
                    />
                  </Col>
                  <Col
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      width: "250px",
                    }}
                  >
                    {compromise?.Title}
                  </Col>
                  <Col
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      width: "280px",
                      marginBottom: "50px",
                    }}
                  >
                    {compromise?.Paragraph}
                  </Col>
                </Row>
              ))}
        </Row>
      ) : (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            width: "90%",
          }}
          className="arrow"
        >
          <Splide
            hasTrack={false}
            options={options}
            aria-label="My Favorite Images"
          >
            <SplideTrack>
              {usSections?.length === 0 || usSections === null
                ? compromises.map((compromises) => (
                    <SplideSlide
                    // style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        id="particular"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",

                          marginLeft: "80px",
                        }}
                      >
                        <Col>
                          <img
                            style={{ marginBottom: "15px" }}
                            src={compromises.icon}
                          />
                        </Col>
                        <Col>
                          <h5
                            style={{
                              fontSize: "20px",
                              width: "250px",
                              fontWeight: "700",
                            }}
                          >
                            {compromises.title}
                          </h5>
                        </Col>

                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: "250px",
                            color: "#717171",
                            minHeight: "150px",
                            textAlign: biggerThan900 ? "center" : "",
                            marginBottom: "30px",
                          }}
                        >
                          {compromises.description}
                        </p>
                      </div>
                    </SplideSlide>
                  ))
                : usSections &&
                  usSections[1]?.Card?.map((compromise) => (
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: biggerThan1300 ? "500px" : "400px",
                      }}
                    >
                      <Col>
                        <img
                          style={{
                            marginBottom: "15px",
                          }}
                          src={compromise?.Icon?.data?.attributes?.url}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          width: "250px",
                        }}
                      >
                        {compromise?.Title}
                      </Col>
                      <Col
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          width: "280px",
                          marginBottom: "50px",
                        }}
                      >
                        {compromise?.Paragraph}
                      </Col>
                    </Row>
                  ))}
            </SplideTrack>
          </Splide>
        </div>
      )}

      <Row justify="center">
        <Col
          style={{
            fontSize: biggerThan900 ? "50px" : "35px",
            width: biggerThan900 ? "" : "350px",
            fontWeight: "600",
            marginTop: biggerThan900 ? "78px" : "30px",
            marginBottom: "70px",
            marginLeft: biggerThan900 ? "" : "15px",
          }}
        >
          {usSections?.length === 0 || usSections === null
            ? "Pioneros en soluciones de Movilidad"
            : usSections && usSections[2]?.Title}
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "120px",
          marginLeft: "15px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginRight: "50px",
          }}
        >
          <Col
            style={{
              width: biggerThan900 ? "594px" : "100%",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "black",
              marginBottom: "70px",
              marginLeft: biggerThan900 ? "" : "30px",
            }}
          >
            {usSections?.length === 0 || usSections === null
              ? "En Gama buscamos entregar soluciones en Movilidad para empresas y personas de forma eficiente, innovadora y a la medida de las necesidades de nuestros clientes."
              : usSections && usSections[2]?.Paragraph}
          </Col>
          <Row
            
            style={{
              display: "flex",
              alignItems:'center'
            }}
          >
            <Row style={{display: 'flex', alignItems: "center"}} align="middle">
              <Col>
                <img
                src={logo1}
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                    height: "60px",
                    width: "60px"
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px" }}>
                {usSections?.length === 0 || usSections === null
                  ? "Compromiso"
                  : usSections && usSections[2]?.Card[0]?.Title}
              </Col>
              <Col
                style={{
                  width: biggerThan900 ? "450px" : "50%",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  minHeight: "42px",
                }}
              >
                {usSections?.length === 0 || usSections === null
                  ? "Comprometidos a entregar soluciones de movilidad de la más alta calidad y eficiencia."
                  : usSections && usSections[2]?.Card[0]?.Paragraph}
              </Col>
            </Row>
          </Row>
          <Row >
            <Row style={{ alignItems: "center"}}>
              <Col>
                <img src={logo2}
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                    height: "60px",
                    width: "60px"
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px" }}>
                {usSections?.length === 0 || usSections === null
                  ? "Versalitidad"
                  : usSections && usSections[2]?.Card[1]?.Title}
              </Col>
              <Col
                style={{
                  width: biggerThan900 ? "450px" : "50%",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {usSections?.length === 0 || usSections === null
                  ? "Entregamos una oferta variada de opciones para las distintas necesidades de nuestros clientes."
                  : usSections && usSections[2]?.Card[1]?.Paragraph}
              </Col>
            </Row>
          </Row>
          <Row >
            <Row style={{ alignItems: "center"}}>
              <Col>
                <img src={logo3}
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                    height: "60px",
                    width: "60px"
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px", minWidth: "70px" }}>
                {usSections?.length === 0 || usSections === null
                  ? "Respeto"
                  : usSections && usSections[2]?.Card[2]?.Title}
              </Col>
              <Col
                style={{
                  width: biggerThan900 ? "450px" : "50%",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {usSections?.length === 0 || usSections === null
                  ? "Basamos las relaciones con nuestros proveedores, aliados y clientes en la transparencia y respeto."
                  : usSections && usSections[2]?.Card[2]?.Paragraph}
              </Col>
            </Row>
          </Row>
        </Row>
        <img
          style={{
            marginTop: "40px",
            width: biggerThan900 ? "" : "350px",
            height: biggerThan900 ? "" : "300px",
            marginLeft: "-15px",
          }}
          src={
            usSections?.length === 0 || usSections === null
              ? Mobility
              : usSections && usSections[2]?.Image?.data?.attributes?.url
          }
        />
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default MobilityUS;
