import axios from "axios";

const BASE_URL = "https://strapi.gamaleasing.cl/api";

export const getGamaMobilityContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gm-mains/1?populate=Navbar.Logo,Navbar.TopLinks,Navbar.NavLinks,Navbar.NavButton`
  );

  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/1?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/1?populate=Sections.Image,Sections.Card.Icon,Sections.Card.Button`
  );

  const { data: footer } = await axios.get(
    `${BASE_URL}/gm-mains/1?populate=Footer.Logo,Footer.SocialNetwork.Icon,Footer.FooterLinks.FooterLink,Footer.FooterNewsletter.NewsletterButton,Footer.ContactCard.Icon,Footer.BottomArea.BottomLinks`
  );

  return {
    navbar: navbar.data.attributes.Navbar,
    header: header.data.attributes.Header,
    sections: sections.data.attributes.Sections,
    footer: footer.data.attributes.Footer,
  };
};

export const getGamaMobilityContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/3?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/3?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );

  return {
    header: header.data.attributes.Header,
    sections: sections.data.attributes.Sections,
  };
};

export const getGamaMobilityUS = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/2?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/2?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );

  return {
    header: header.data.attributes.Header,
    sections: sections.data.attributes.Sections,
  };
};

export const getGamaMobilityBlog = async () => {
  const { data } = await axios.get(
    `${BASE_URL}/gm-mains/9?populate=Header.Title%2cHeader.BannerImage%2cSections.Image%2cSections.Card.Icon%2cSections.Card.Button`
  );
  return {
    header: data?.data?.attributes.Header,
    sections: data?.data.attributes?.Sections,
  };
};
